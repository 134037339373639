<script setup lang="ts">
import {
  SelectItem,
  SelectItemIndicator,
  type SelectItemProps,
} from "radix-vue";
import type { HTMLAttributes } from "vue";

const props = withDefaults(
  defineProps<
    SelectItemProps & {
      class?: HTMLAttributes["class"];
      indicatorPosition?: "start" | "end";
    }
  >(),
  {
    indicatorPosition: "end",
    class: "",
  },
);

const delegatedProps = computed(() => {
  const { class: _, indicatorPosition, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <SelectItem
    v-bind="delegatedProps"
    :class="
      cn(
        'relative flex w-full cursor-default select-none items-center rounded-md py-2 text-base text-on-surface outline-none hover:bg-surface-container-highest focus:bg-surface-container-highest data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        props.indicatorPosition === 'start' ? 'pl-10 pr-3' : 'pl-3 pr-10',
        props.class,
      )
    "
  >
    <SelectItemIndicator
      :class="
        cn(
          'absolute flex items-center justify-center',
          props.indicatorPosition === 'start' ? 'left-3' : 'right-3',
        )
      "
    >
      <slot name="indicator">
        <Icon name="mdi:check" class="size-4" />
      </slot>
    </SelectItemIndicator>

    <UISelectItemText>
      <slot />
    </UISelectItemText>
  </SelectItem>
</template>
