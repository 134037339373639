<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { SelectIcon, SelectTrigger, type SelectTriggerProps } from "radix-vue";
import { inputVariants } from "../Input/input-variants";

const props = withDefaults(
  defineProps<
    SelectTriggerProps & {
      class?: HTMLAttributes["class"];
      invalid?: boolean;
      position?: "start" | "end" | "center" | "isolated";
    }
  >(),
  {
    class: "",
    invalid: false,
    position: "isolated",
  },
);
</script>

<template>
  <SelectTrigger
    v-bind="props"
    :class="[
      cn(
        inputVariants({
          class: 'flex items-center justify-between text-left',
          position: props.position,
          invalid: props.invalid,
        }),
        props.class,
      ),
    ]"
  >
    <slot />
    <SelectIcon as-child>
      <slot name="icon">
        <Icon
          name="mdi:chevron-down"
          class="size-5 shrink-0 text-on-surface-variant"
        />
      </slot>
    </SelectIcon>
  </SelectTrigger>
</template>
